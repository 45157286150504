import { useState, useEffect, useContext, useRef } from "react";
import { usePlatforms } from "../../../../hooks/usePlatforms";
import { MdEdit } from "react-icons/md";
import { MdSave } from "react-icons/md";
import { RiArrowLeftLine } from "react-icons/ri";
import LoadingScreen from "../../../../components/LoadingScreen";

import * as RiIcons from "react-icons/ri";
import "./index.css";

import Spinner from "../../../../components/Spinner";
import {
  copyToClipboard,
  getFormatDate,
  formatter,
  translate,
  verifyHideCountry,
  findActionsByPath,
} from "../../../../utils/Common";
import { useForm } from "react-hook-form";
import ModalContext from "../../../../contexts/modal";
import "./index.css";
// import ImagePreview from "../../../../components/ImagePreview";
import { FaHistory, FaSignal, FaPowerOff  } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";
import SmartLocker from "../SmartLocker";
import ShowTable from "../../../../components/ShowTable";
import ItemDoorPlatforms from "../../../../components/ShowTable/ItemDoorPlatforms";
import { useLocation } from "react-router-dom";
import { configRolesUsers, verifyAccessViewPermissions } from "../../../../config/permissions";
import { LuAlertCircle } from "react-icons/lu";

const PlatformDetails = ({
  setPlatformSelected,
  platformSelected,
  onRefresh,
}) => {
  const { updateDetails, getPlatformDetails, rebootLocation } = usePlatforms();
  const [platformDetails, setPlatformDetails] = useState(null);
  const { register, errors, handleSubmit, setError, clearErrors } = useForm();
  const modalContext = useContext(ModalContext);
  const refMovementDoors = useRef(null);


  const statusLog =
    (platformSelected.IdEstatus || platformSelected.statusId) === 1
      ? true
      : false;
  const [active, setActive] = useState(statusLog);

  const [editActive, setEditActive] = useState(false);
  const [filterMovementSelect, setFilterMovementSelect] = useState(null);
  const [actionsPerms, setActionsPerms] = useState([]);
  const location = useLocation();

  const [listMovements, setListMovements] = useState(null);
  const [headerRow] = useState(
    <tr>
      <th>ID {translate("global.movement")}</th>
      <th>{translate("global.movementType")}</th>
      <th>{translate("dashboard.packages.details.numPackage")}</th>
      <th>{translate("dashboard.packages.details.numTracking")}</th>
      <th>{translate("global.movementDate")}</th>
      <th>{translate("global.currentStatusPackage")}</th>
    </tr>
  );

  const handleUpdate = async (data) => {
    try {
      if (data.latlng?.includes(",")) {
        modalContext.setIsOpenModal(false);
        clearErrors();
        let dataToSend = {};
        dataToSend.idPlataforma = platformDetails.IdPlataforma;
        dataToSend.IdEstatus = active;

        if (data.nombre.toUpperCase() !== (platformDetails.Nombre ? platformDetails.Nombre.toUpperCase() : ""))
          dataToSend.nombre = data.nombre;
        else dataToSend.nombre = platformDetails.Nombre;
        
        if (data.ip.toUpperCase() !== (platformDetails.IP ? platformDetails.IP.toUpperCase() : ""))
          dataToSend.ip = data.ip;
        else dataToSend.ip = platformDetails.IP;
        
        if (data.direccionfisica.toUpperCase() !== (platformDetails.Direccion_Fisica ? platformDetails.Direccion_Fisica.toUpperCase() : ""))
          dataToSend.direccionfisica = data.direccionfisica;
        else dataToSend.direccionfisica = platformDetails.Direccion_Fisica;
        
        if (data.latlng.toUpperCase() !== (platformDetails.LatLng ? platformDetails.LatLng.toUpperCase() : ""))
          dataToSend.latlng = data.latlng;
        else dataToSend.latlng = platformDetails.LatLng;
        
        if (data.linkubicacion.toUpperCase() !== (platformDetails.LinkUbicacion ? platformDetails.LinkUbicacion.toUpperCase() : ""))
          dataToSend.linkubicacion = data.linkubicacion;
        else dataToSend.linkubicacion = platformDetails.LinkUbicacion;
        
        if (data.refExtern.toUpperCase() !== (platformDetails.ReferenciaExterna ? platformDetails.ReferenciaExterna.toUpperCase() : ""))
          dataToSend.ReferenciaExterna = data.refExtern;
        else dataToSend.ReferenciaExterna = platformDetails.ReferenciaExterna;
        
        if (data.refIntern.toUpperCase() !== (platformDetails.ReferenciaInterna ? platformDetails.ReferenciaInterna.toUpperCase() : ""))
          dataToSend.ReferenciaInterna = data.refIntern;
        else dataToSend.ReferenciaInterna = platformDetails.ReferenciaInterna;

        const updateResult = await updateDetails(dataToSend);
        if (updateResult.message === "UPDATED_SUCCESS") {
          modalContext.confirm(
            translate("global.alerts.successTransact"),
            translate("dashboard.platforms.details.success"),
            <button
              type="reset"
              key={2}
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => modalContext.setIsOpenModal(false)}
            >
              {translate("global.accept")}
            </button>,
            true
          );
          onRefresh();
          setEditActive(!editActive);
        } else {
          modalContext.confirm(
            translate("global.alerts.ops"),
            <span>{translate("dashboard.platforms.create.alert.error")}</span>,
            <button
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => modalContext.setIsOpenModal(false)}
            >
              {translate("global.accept")}
            </button>,
            true
          );
        }
      } else {
        modalContext.setIsOpenModal(false);
        setError("latlng", {
          message: translate("dashboard.platforms.details.formatIncorrect"),
        });
      }
    } catch (error) {
      modalContext.confirm(
        translate("global.alerts.ops"),
        <span>{translate("dashboard.platforms.create.alert.error")}</span>,
        <button
          type="button"
          className="btn btn-sm btn-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.accept")}
        </button>,
        true
      );
      console.log(error);
    }
  };

  const handleResetLocker = async () => {
    
    modalContext.confirm(
      translate("global.continue") + "?",
      translate("global.question.continueResetLocker"),
      [
        <button
          type="button"
          key={1}
          className="btn btn-sm btn-fucsia"
          disabled
        >
          <Spinner type={1} size={20} />
        </button>,
        <button
          key={2}
          className="btn btn-sm btn-outline-fucsia"
          disabled
        >
          {translate("global.cancel")}
        </button>,
      ],
      true
    );
    
    const platform = platformSelected.boxitLocationId;
    const response = await rebootLocation({ platformId: platform });

    if (response.code === 200) {
      modalContext.confirm(
        translate("global.alerts.successTransact"),
        translate("global.successResetLocker"),
        <button
          type="reset"
          key={2}
          className="btn btn-sm btn-outline-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.accept")}
        </button>,
        true
      );
      onRefresh();
    } else if(response.message === "INSTRUCTION_NO_SENT_WAIT_10_MINUTES") {
      modalContext.confirm(
        translate("global.alerts.ops"),
        <span>{translate("dashboard.platforms.create.alert.error10minutes")}</span>,
        <button
          type="button"
          className="btn btn-sm btn-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.accept")}
        </button>,
        true
      );
    } else {
      modalContext.confirm(
        translate("global.alerts.ops"),
        <span>{translate("dashboard.platforms.create.alert.error")}</span>,
        <button
          type="button"
          className="btn btn-sm btn-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.accept")}
        </button>,
        true
      );
    }
  }

  const renderButtonsActions = (
    <div className="mb-3 d-flex align-items-center">
      <button
        className="btn btn-outline-fucsia d-flex align-items-center mr-2"
        type="back"
        onClick={() => {
          setPlatformSelected(null);
          onRefresh();
        }}
      >
        <RiArrowLeftLine /> {translate("global.return")}
      </button>
      {
        actionsPerms.includes("update_locker") && (
          <>
           {!editActive ? (
        <div
          className="btn btn-outline-fucsia d-flex align-items-center"
          onClick={() => setEditActive(!editActive)}
        >
          <MdEdit className="mr-1" /> {translate("global.modify")}
        </div>
      ) : (
        <button
          type="submit"
          className="btn btn-outline-fucsia d-flex align-items-center"
        >
          <MdSave className="mr-1" /> {translate("global.save")}
        </button>
      )}
          </>
        )
      }
     
    </div>
  );

  const handleConfirm = (data) => {
    modalContext.confirm(
      translate("global.continue") + "?",
      translate("global.question.continueChanges"),
      [
        <button
          type="button"
          key={1}
          className="btn btn-sm btn-fucsia"
          onClick={() => handleUpdate(data)}
        >
          {translate("global.accept")}
        </button>,
        <button
          type="reset"
          key={2}
          className="btn btn-sm btn-outline-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.cancel")}
        </button>,
      ],
      true
    );
  };

  const handleId = () => {
    if (platformSelected.locationId && platformSelected.locationId !== "") {
      if (platformSelected.zipCode)
        return `/ ID. ${platformSelected.locationId}`;
      else return `ID. ${platformSelected.locationId}`;
    } else return "";
  };

  const handleZipCode = () => {
    if (platformSelected.zipCode && platformSelected.zipCode !== "") {
      if (!verifyHideCountry()) return `CEP: ${platformSelected.zipCode}`;
      else return `Zip Code: ${platformSelected.zipCode}`;
    } else return "";
  };

  const setPermsActions = () => {
    const path = location.pathname;
    const user = JSON.parse(sessionStorage.getItem('user'))
    const data = configRolesUsers[user?.roleId]

    const actions = findActionsByPath(data, path);
    setActionsPerms(actions);
  }

  useEffect(() => {
    const abortController = new AbortController();
    const handlePlatform = async () => {
      const response = await getPlatformDetails(
        platformSelected.boxitLocationId,
        abortController.signal
      );
      setPlatformDetails(response);
    };
    handlePlatform();
    setPermsActions();
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [copied, setCopied] = useState(false);

  const handleCopyClipboard = (text) => {
    copyToClipboard(text);
    setCopied(true);

    // Después de 2 segundos, resetea el estado de "copied"
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleFocusMovements = () => {
    refMovementDoors.current.focus();
  };

  const handleRestartButtonClick = () => {

    modalContext.confirm(
      translate("global.continue") + "?",
      translate("global.question.continueResetLocker"),
      [
        <button
          type="button"
          key={1}
          className="btn btn-sm btn-fucsia"
          onClick={() => handleResetLocker()}
        >
          {translate("global.accept")}
        </button>,
        <button
          type="reset"
          key={2}
          className="btn btn-sm btn-outline-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.cancel")}
        </button>,
      ],
      true
    );

  };

  return !platformDetails ? (
    <div className="loader center">
      <Spinner type={0} size={40} />
    </div>
  ) : (
    <>
    {platformDetails?.Result === 1   ? (
       <div style={{ padding: "1rem" }}>
       <form
         className="package-info m-0 p-0 text-left font-small"
         onSubmit={handleSubmit(handleConfirm)}
         id="sendform"
       >
         {renderButtonsActions}
         <div className="row">
           {/* -- Card Info Platform */}
           <div className="col-lg-6 col-sm-12 mb-4">
             <div className="card-details-invoicing">
               {/* -- Header -- */}
               <div
                 className="p-4"
                 style={{
                   borderBottom: "1px solid #d4d4d4",
                   background: "#fafafa",
                   borderRadius: "0.5rem 0.5rem 0 0",
                 }}
               >
                 <div>
                   <div className="d-flex justify-content-between">
                     <input
                       ref={register}
                       type="text"
                       defaultValue={
                         platformDetails?.Nombre ? platformDetails.Nombre : ""
                       }
                       style={{ fontSize: "14px" }}
                       readOnly={!editActive}
                       className={
                         "form-control form-control-md font-small mb-2 mr-3" +
                         (errors.nombre ? " mb-2 is-invalid" : "")
                       }
                       id="nombre"
                       name="nombre"
                     />

                     {errors.nombre && (
                       <span className="text-danger pt-2">
                         {errors.nombre.message}
                       </span>
                     )}
                     <div>

                       {platformSelected.locationStatus && (
                         <>
                           {platformSelected.locationStatus === "ONLINE" ? (
                             <FaSignal color="rgb(74, 222, 128)" size={20} />
                           ) : (
                             <IoMdAlert color="red" size={20} />
                           )}
                         </>
                       )}

                     </div>
                   </div>

                   <p style={{ color: "#858585", marginBottom: 0 }}>
                     {handleZipCode()} {handleId()}{" "}
                   </p>
                   {verifyAccessViewPermissions(["reset_locker"]) && !verifyHideCountry() && (
 <button type="button" className="mt-2 btn btn-outline-blue" onClick={handleRestartButtonClick} style={{fontSize:"12px"}}>
 <span className="mr-2"><FaPowerOff /></span>
 <span>{translate("global.resetLocker")}</span>
</button>
                   )}
                 

                 </div>
               </div>

               {/* -- Body -- */}
               <div className="card-billing-section">
                 <div className="form-row"></div>

                 <div className="row">
                   <div className="col-3 pl-2">
                     <label className="mb-1" htmlFor="inputIdPaquete">
                       {" "}
                       {translate("global.state")}
                     </label>
                     <div className="form-group">
                       <div className="custom-control custom-switch mb-2">
                         <input
                           className="custom-control-input form-control"
                           checked={active}
                           onChange={(e) => setActive(!active)}
                           type="checkbox"
                           id="inputEstado"
                           disabled={!editActive}
                           hidden
                         />
                         <label
                           className="custom-control-label mb-1"
                           htmlFor="inputEstado"
                         >
                           {active ? (
                             <span className="text-success text-bold">
                               {translate("global.state.active")}
                             </span>
                           ) : (
                             <span className="text-danger text-bold">
                               {translate("global.state.inactive")}
                             </span>
                           )}
                         </label>
                       </div>
                     </div>
                   </div>

                   <div className="col-3">
                     <div className="form-group mb-2">
                       <label className="mb-1" htmlFor="Tipo">
                         {" "}
                         {translate("global.type")}
                       </label>
                       <input
                         required
                         type="text"
                         value={
                           platformDetails?.Tipo ? platformDetails.Tipo : ""
                         }
                         readOnly
                         className="form-control font-small form-control-plaintext font-bold pl-3"
                         id="Tipo"
                       />
                     </div>
                   </div>

                   <div className="col-6">
                     <div className="form-group mb-2 ">
                       <label className="mb-1" htmlFor="IP">
                         IP
                       </label>
                       <input
                         type="text"
                         defaultValue={
                           platformDetails?.IP ? platformDetails.IP : ""
                         }
                         ref={register({
                           required: {
                             value: true,
                             message: translate("global.isRequerid"),
                           },
                         })}
                         className={
                           "form-control form-control-md font-small" +
                           (errors.ip ? " mb-2 is-invalid" : "")
                         }
                         id="inputNumeroPaquete"
                         name="ip"
                         disabled={!editActive}
                       />
                       {errors.ip && (
                         <span className="text-danger">
                           {errors.ip.message}
                         </span>
                       )}
                     </div>
                   </div>

                   <div className="col-12">
                     <div className="form-group mb-2">
                       <label className="mb-1" htmlFor="Direccion_Fisica">
                         {translate("global.address")}
                       </label>
                       <input
                         type="text"
                         defaultValue={
                           platformDetails?.Direccion_Fisica
                             ? platformDetails.Direccion_Fisica
                             : ""
                         }
                         ref={register({
                           required: {
                             value: true,
                             message: translate("global.isRequerid"),
                           },
                         })}
                         className={
                           "form-control form-control-md font-small" +
                           (errors.direccionfisica ? " mb-2 is-invalid" : "")
                         }
                         id="inputNumeroPaquete"
                         name="direccionfisica"
                         disabled={!editActive}
                       />
                       {errors.direccionfisica && (
                         <span className="text-danger">
                           {errors.direccionfisica.message}
                         </span>
                       )}
                     </div>
                   </div>

                   <div className="col-6">
                     <div className="form-group mb-2 ">
                       <label className="mb-1" htmlFor="LatLng">
                         {translate("global.coordinates")}{" "}
                         <span>ej: 9.0281749,-79.522551</span>
                       </label>
                       <input
                         type="text"
                         defaultValue={platformDetails.LatLng}
                         ref={register({
                           required: {
                             value: true,
                             message: translate("global.isRequerid"),
                           },
                         })}
                         className={
                           "form-control form-control-md font-small" +
                           (errors.latlng ? " mb-2 is-invalid" : "")
                         }
                         id="inputNumeroPaquete"
                         name="latlng"
                         disabled={!editActive}
                       />
                       {errors.latlng && (
                         <span className="text-danger mt-1">
                           {errors.latlng.message}
                         </span>
                       )}
                     </div>
                   </div>
                   <div className="col-6">
                     <div className="form-group mb-2 ">
                       <label className="mb-1" htmlFor="inputNumeroPaquete">
                         Link
                       </label>
                       <div
                         className="d-flex justify-align-center align-items-center"
                         style={{ gap: 5 }}
                       >
                         <div
                           onClick={() =>
                             handleCopyClipboard(platformDetails.LinkUbicacion)
                           }
                           id="btnGroupAddon"
                         >
                           <RiIcons.RiFileCopyFill
                             className="mr-1"
                             size={14}
                           />
                         </div>
                         <input
                           type="text"
                           defaultValue={platformDetails?.LinkUbicacion}
                           ref={register({
                             required: {
                               value: true,
                               message: translate("global.isRequerid"),
                             },
                           })}
                           id="inputNumeroPaquete"
                           name="linkubicacion"
                           className={
                             "form-control form-control-md font-small" +
                             (errors.linkubicacion ? " mb-2 is-invalid" : "")
                           }
                           disabled={!editActive}
                         />
                       </div>
                       {copied && (
                         <p style={{ color: "green" }}>
                           ¡{translate("global.copied")}!
                         </p>
                       )}
                       {errors.linkubicacion && (
                         <span className="text-danger">
                           {errors.linkubicacion.message}
                         </span>
                       )}
                     </div>
                   </div>

                   <div className="col-6">
                     <div className="form-group mb-2 ">
                       <label className="mb-1" htmlFor="SaldoEfectivo">
                         {translate("dashboard.platforms.details.cashBalance")}
                       </label>
                       <input
                         type="text"
                         value={formatter.format(
                           platformDetails?.SaldoEfectivo
                             ? platformDetails.SaldoEfectivo
                             : ""
                         )}
                         className="form-control form-control-sm font-small"
                         id="inputNumeroPaquete"
                         readOnly
                       />
                     </div>
                   </div>

                   <div className="col-6">
                     <div className="form-group mb-2 ">
                       <label className="mb-1" htmlFor="FechaCreacion">
                         {translate("dashboard.platforms.details.createDate")}
                       </label>
                       <input
                         type="text"
                         value={
                           platformDetails?.FechaCreacion
                             ? getFormatDate(platformDetails.FechaCreacion)
                             : ""
                         }
                         readOnly
                         className="form-control form-control-sm font-small"
                         id="inputNumeroPaquete"
                       />
                     </div>
                   </div>

                   <div className="col-12">
                     <div className="form-group mb-2 ">
                       <label className="mb-1" htmlFor="refIntern">
                         {translate("global.referenceIntern")}
                       </label>

                       <textarea
                         ref={register}
                         disabled={!editActive}
                         className="form-control form-control-sm font-small"
                         rows="2"
                         name="refIntern"
                       >
                         {platformDetails?.ReferenciaInterna
                           ? platformDetails.ReferenciaInterna
                           : ""}
                       </textarea>
                     </div>
                   </div>

                   <div className="col-12">
                     <div className="form-group mb-2 ">
                       <label className="mb-1" htmlFor="refExtern">
                         {translate("global.referenceExtern")}
                       </label>
                       <textarea
                         ref={register}
                         className="form-control form-control-sm font-small"
                         rows="2"
                         disabled={!editActive}
                         name="refExtern"
                       >
                         {platformDetails?.ReferenciaExterna
                           ? platformDetails.ReferenciaExterna
                           : ""}
                       </textarea>
                     </div>
                   </div>

                   <div className="col-6">
                     <div className="form-group mb-2 ">
                       <label className="mb-1" htmlFor="FechaCreacion"></label>
                       <div>
                         {translate(
                           "dashboard.platforms.details.lastConnection"
                         )}{" "}
                         {getFormatDate(platformDetails.FechaCreacion)}
                       </div>
                     </div>
                   </div>
                 </div>

                 <hr />
               </div>
             </div>
           </div>

           {/* -- Card Image Platform */}
           <div className="col-lg-6 col-sm-12">
             <div className="card-details-invoicing">
               {/* -- Image -- */}
               <div>
                 <SmartLocker
                   idPlatform={platformSelected.locationId}
                   setListMovements={setListMovements}
                   setFilterMovementSelect={setFilterMovementSelect}
                   handleFocusMovements={handleFocusMovements}
                   actionsPerms={actionsPerms}
                 />
               </div>
             </div>
           </div>

           {listMovements?.length > 0 && (
             <div className="col-12 mt-4">
               <div
                 className="card-details-invoicing"
                 style={{ padding: "25px" }}
               >
                 <div style={{ marginBottom: "15px" }}>
                   <div
                     className="d-flex align-items-center"
                     style={{ gap: 6, marginBottom: "10px" }}
                   >
                     <FaHistory size={16} />
                     <h5>{translate("global.movementsDoors")}</h5>
                   </div>

                   <div>
                     {filterMovementSelect && (
                       <div
                         style={{
                           fontSize: "18px",
                           display: "flex",
                           flexDirection: "row",
                           flexWrap: "wrap",
                           gap: 8,
                         }}
                       >
                         <span className="badge badge-pill btn-fucsia p-2">
                           {translate("global.door")}:{" "}
                           {filterMovementSelect?.numDoor}
                         </span>
                         <span className="badge badge-pill badge-secondary p-2">
                           {translate("dashboard.accounting.from")}:{"  "}{" "}
                           {filterMovementSelect?.dateFrom}
                         </span>
                         <span className="badge badge-pill badge-secondary p-2">
                           {translate("dashboard.accounting.to")}:{"  "}{" "}
                           {filterMovementSelect?.dateTo}
                         </span>
                       </div>
                     )}
                   </div>
                 </div>

                 <ShowTable
                   headerRow={headerRow}
                   itemArray={listMovements}
                   Item={(props) => <ItemDoorPlatforms {...props} />}
                   noItemsFound={translate(
                     "dashboard.delivery.table.noItemsFound"
                   )}
                   actualPage={1}
                   itemsShowing={listMovements?.length}
                 />

                 <input
                   type="text"
                   id="table-movements-doors"
                   ref={refMovementDoors}
                   style={{ opacity: "0" }}
                 />
               </div>
             </div>
           )}
         </div>
       </form>
     </div>
           ) : (<>
  <div
            className="package-info p-5 d-flex justify-content-center align-items-center"
            style={{ fontSize: 14 }}
          >
              <button
           className="btn btn-outline-fucsia d-flex align-items-center mr-2"
           type="back"
           onClick={() => {
             setPlatformSelected(null);
             onRefresh();
           }}
         >
           <RiArrowLeftLine /> {translate("global.return")}
         </button>
            <LuAlertCircle size={20} className="mr-2" />{" "}
            {translate("global.NOT_AVAILABLE")}

          
          </div>
         
           </>
           )
    }
     
    </>
  );
};

export default PlatformDetails;
