import { useState, useRef, useContext, useCallback, useEffect } from "react";
import { round, getClientDomain, getToken } from "../../../../utils/Common";
import AuthContext from "../../../../contexts/auth";
import ModalContext from "../../../../contexts/modal";
import { useForm } from "react-hook-form";
import { usePackages } from "../../../../hooks/usePackages";
// icons
import * as MdIcons from "react-icons/md";
// components
import Tooltip from "../../../../components/Tooltip";
import FieldSizes from "./FieldSizes";
import LockerSizes from "./LockerSizes";
import SearchClient from "./SearchClient";
import PlatformList from "../../../../components/PlatformList";
import DoorList from "../../../../components/DoorList";
import ProviderList from "../../../../components/ProviderList";
import { translate } from "../../../../utils/Common";
import PlatformSearchList from "../../../../components/PlatformSearchList";
import { IoMdCopy } from "react-icons/io";
import ClientsSearchList from "../../../../components/ClientsSearchList";

// formulario para carga tipo B2B / Emprendedores
const FormB2BEmprendedores = (props) => {
  const userContext = useContext(AuthContext);
  const { createPackage } = usePackages();
  const userRole = JSON.parse(sessionStorage.getItem("user")).roleId;
  const modalContext = useContext(ModalContext);
  const {
    register,
    errors,
    clearErrors,
    setError,
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm();

  // para saber qué unidades de medida utilizar --> 0 = cm/kg, 1 = inch/lb
  const [unidad, setUnidad] = useState(1);
  // para saber el tipo de ingreso de medidas
  const [ingresarMedidas, setIngresarMedidas] = useState(false);

  const [selectedClient, setSelectedClient] = useState(null);
  const [providor, setProvidor] = useState(null);
  const [platformSelect, setPlatformSelect] = useState(null);

  // para saber qué boxit se seleccionó
  const [isBoxit, setIsBoxit] = useState(false);
  const idPlatform = useRef({});
  idPlatform.current = watch("plataforma", "");
  const handleIsBoxit = useCallback((value) => setIsBoxit(value), []);

  // clear formulario
  const resetFormB2BEmprendedores = () => {
    reset();
    clearErrors();

    setIngresarMedidas(false);
    setUnidad(1);
    setIsBoxit(false);
    setPlatformSelect(null);
    setSelectedClient(null);
    setProvidor(null);
  };

  const [copySuccess, setCopySuccess] = useState(false);

  // Función para copiar el número de paquete al portapapeles
  const copyToClipboard = (packageNumber) => {
    navigator.clipboard.writeText(packageNumber).then(() => {
      setCopySuccess(true); // Mostrar mensaje de éxito
      setTimeout(() => setCopySuccess(false), 3000); // Ocultar mensaje después de 3 segundos
    });
  };

  const [isSendingData, setIsSendingData] = useState(false);

  // registrar paquete en el servidor
  const sendPackage = async (data) => {
    try {
      setIsSendingData(true);

      if (userContext.user?.IsAdmin) {
        if (!selectedClient) {
          setError("buscarCliente", {
            type: "",
            message: "Debes elegir un cliente válido.",
          });
          return;
        }
      }

      if (!userContext.user?.IsAdmin) {
        data.proveedor = userContext.user?.Ruc;
        data.cliente = userContext.user?.IdCliente;
        data.idTipoUsuario = userContext.user?.IdTipoUsuario;
      } else {
        data.proveedor = providor?.IdUsuario;
        data.cliente = selectedClient ? selectedClient : "";
        data.idTipoUsuario = providor?.IdTipoUsuario;
      }

      // validar datos a enviar
      if (data.puerta) {
        if (
          data.puerta === "No hay puertas disponibles" ||
          data.puerta === translate("global.select")
        )
          data.puerta = "";
      }
      if (unidad) {
        if (data.alto) data.alto = round(data.alto * 2.54, 2);
        if (data.largo) data.largo = round(data.largo * 2.54, 2);
        if (data.ancho) data.ancho = round(data.ancho * 2.54, 2);
      } else {
        if (data.peso) data.peso = Math.ceil(data.peso * 2.205);
      }

      data.idTipoPaquete = props.idTipoPaquete;

      const controller = new AbortController();
      const result = await createPackage(data, controller.signal);

      if (result) {
        if (result.code === 200) {
          if (result.payload) {
            if (result.payload.Procesado === "1") {
              modalContext.confirm(
                translate(
                  "dashboard.packages.loadpackage.formProvider.successTittle"
                ),
                result.payload.PackageNumber ? (
                  <div>
                    {translate(
                      "dashboard.packages.loadpackage.formProvider.successInfo"
                    )}
                    <div>
                      {translate("global.numPackageIs")}{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {result.payload.PackageNumber}
                      </span>
                      <button
                        className="btn"
                        onClick={() =>
                          copyToClipboard(result.payload.PackageNumber)
                        }
                      >
                        <IoMdCopy size={20} />
                      </button>
                    </div>
                    {copySuccess && (
                      <div style={{ color: "green" }}>¡Copiado con éxito!</div>
                    )}
                  </div>
                ) : (
                  translate(
                    "dashboard.packages.loadpackage.formProvider.successInfo"
                  )
                ),
                <button
                  type="button"
                  className="btn btn-sm btn-fucsia"
                  onClick={() => modalContext.setIsOpenModal(false)}
                >
                  {translate("global.accept")}
                </button>,
                true
              );
              resetFormB2BEmprendedores();
            }
            if (result.payload.Mensaje === "RESERVATION MADE SUCCESSFULLY") {
              modalContext.confirm(
                translate(
                  "dashboard.packages.loadpackage.formProvider.successTittle"
                ),
                <div>
                  {translate(
                    "dashboard.packages.loadpackage.formProvider.successInfoReserved"
                  )}
                  <div>
                    {translate("global.status.reservedDoor")} :{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {result.payload.Locker}
                    </span>
                  </div>
                </div>,
                <button
                  type="button"
                  className="btn btn-sm btn-fucsia"
                  onClick={() => modalContext.setIsOpenModal(false)}
                >
                  {translate("global.accept")}
                </button>,
                true
              );
              resetFormB2BEmprendedores();
            } else {
              const errorHandlers = {
                "El número de paquete ya se encuentra registrado.": () =>
                  setError("paquete", {
                    type: "",
                    message: translate(
                      "dashboard.packages.loadpackages.formProvider.packageRegistered"
                    ),
                  }),
                "El número de tracking ya se encuentra registrado.": () =>
                  setError("tracking", {
                    type: "",
                    message: translate(
                      "dashboard.packages.loadpackages.formProvider.trackingRegistered"
                    ),
                  }),
                "No tienes autorización para entregar en este Boxit.": () =>
                  setError("plataforma", {
                    type: "",
                    message: translate(
                      "dashboard.packages.loadpackages.formProvider.notAuthorized"
                    ),
                  }),
              };

              const errorMessage = result.payload.Mensaje;
              if (errorHandlers[errorMessage]) {
                errorHandlers[errorMessage]();
              }
            }
          } else
            modalContext.confirm(
              translate("global.alerts.ops"),
              translate(
                "dashboard.packages.loadpackages.formProvider.errorRegisterPackage"
              ),
              <button
                type="button"
                className="btn btn-sm btn-outline-fucsia"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.accept")}
              </button>,
              true
            );
        } else
          modalContext.confirm(
            translate("global.alerts.ops"),
            translate(
              "dashboard.packages.loadpackages.formProvider.errorRegisterPackage"
            ),
            <button
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => modalContext.setIsOpenModal(false)}
            >
              {translate("global.accept")}
            </button>,
            true
          );
      }
    } catch (err) {
      console.log(err);
      modalContext.confirm(
        translate("global.alerts.ops"),
        translate(
          "dashboard.packages.loadpackages.formProvider.errorRegisterPackage"
        ),
        <button
          type="button"
          className="btn btn-sm btn-outline-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.accept")}
        </button>,
        true
      );
    } finally {
      setIsSendingData(false);
    }
  };

  return (
    <>
      <form
        className="carga-b2b-emprendedores m-0 p-0"
        onSubmit={handleSubmit(sendPackage)}
      >
        <div className="row px-3">
          {userContext.user?.IsAdmin && (
            <>
              <div className="col-sm-12 col-md-6 col-lg-6 mb-2 px-1">
                <label htmlFor="selectProveedor" className="mb-1 mt-2">
                  <span className="text-error text-bold">*</span>{" "}
                  {translate("global.business")}:
                </label>
                <ProviderList
                  register={register}
                  errors={errors}
                  nameShow={translate("global.select")}
                  idShow={props.idTipoUsuario}
                  readOnly={false}
                  setValue={setValue}
                  valueId={"proveedor"}
                  setProveedor={setProvidor}
                  selectedOption={providor}
                />
              </div>

              {providor && (
                <div className="col-sm-12 col-md-6 col-lg-6 mb-2 px-1">
                  <label htmlFor="selectProveedor" className="mb-1 mt-2">
                    <span className="text-error text-bold">*</span>{" "}
                    {translate("global.client")}:
                  </label>
                  <ClientsSearchList
                    idUsuarioSelected={providor?.value}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    register={register}
                    idTipoCliente={props.idTipoCliente}
                    setSelectOption={setSelectedClient}
                    autoSelectClient
                  />
                </div>
              )}
            </>
          )}
        </div>

        <div className="form-row mx-0">
          {userContext.user.IdTipoUsuario !== 3 && (
            <div className="form-group col-sm-12 col-md-6 mb-1">
              <label htmlFor="inputNumeroPaquete" className="mb-1 mt-2">
                <span className="text-error text-bold">*</span>{" "}
                {translate(
                  "dashboard.packages.loadpackages.formProvider.numPackage"
                )}
                :
              </label>
              <input
                className={
                  "form-control form-control-sm font-small" +
                  (errors.paquete ? " is-invalid" : "")
                }
                type="text"
                id="inputNumeroPaquete"
                name="paquete"
                ref={register({
                  required: {
                    value: true,
                    message: translate("global.error.notValidPackages"),
                  },
                })}
              />
              {errors.paquete && (
                <span className="text-error">{errors.paquete.message}</span>
              )}
            </div>
          )}

          <div className="form-group col-sm-12 col-md-6 mb-1">
            <label htmlFor="inputNumeroTracking" className="mb-1 mt-2">
              <span className="text-error text-bold">*</span>{" "}
              {translate(
                "dashboard.packages.loadpackages.formProvider.numTracking"
              )}
              :
            </label>
            <input
              className={
                "form-control form-control-sm font-small" +
                (errors.tracking ? " is-invalid" : "")
              }
              type="text"
              id="inputNumeroTracking"
              name="tracking"
              ref={register({
                required: {
                  value: true,
                  message: translate(
                    "dashboard.packages.loadpackages.formProvider.notValidTracking"
                  ),
                },
              })}
            />
            {errors.tracking && (
              <span className="text-error">{errors.tracking.message}</span>
            )}
          </div>

          <div className="form-group col-sm-12 col-md-6 mb-1">
            <label htmlFor="inputEmail" className="mb-1 mt-2">
              <span className="text-error text-bold">*</span>{" "}
              {translate("global.email")}:
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputEmail">
                  <MdIcons.MdEmail size={18} />
                </span>
              </div>
              <input
                name="email"
                type="email"
                className={
                  "form-control form-control-md font-small " +
                  (errors.email ? " is-invalid" : "")
                }
                id="inputEmail"
                ref={register({
                  required: {
                    value: true,
                    message: translate(
                      "dashboard.packages.loadpackages.formProvider.emailValidate"
                    ),
                  },
                  maxLength: {
                    value: 60,
                    message: translate(
                      "dashboard.userprofile.personal.email_max_length"
                    ),
                  },
                })}
              />
              {errors.email && (
                <div className="invalid-feedback text-error">
                  {errors.email.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="form-row mx-0">
          <div className="form-group col-12 col-md-12 mb-1">
            <label htmlFor="inputDescripcion" className="mb-1 mt-2">
              {translate("global.description")}:{" "}
              <span className="font-xsmall">
                ({translate("global.optional")})
              </span>
            </label>
            <textarea
              className="form-control form-control-sm font-small"
              rows="2"
              id="inputDescripcion"
              name="descripcion"
              ref={register}
            />
          </div>
        </div>

        <div className="form-row mx-0">
          <div className="form-group mb-1 mt-2 col-12 col-md-4">
            <div className="form-group col-12 m-0 p-0">
              <div className="custom-control custom-switch">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  checked={ingresarMedidas ? true : false}
                  onChange={(e) => setIngresarMedidas(e.target.checked)}
                  id="inputTipoIngresoMedidas"
                />
                <label
                  htmlFor="inputTipoIngresoMedidas"
                  className="custom-control-label"
                >
                  {translate(
                    "dashboard.packages.loadpackages.formProvider.packageMeasures"
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-row mx-0">
          {ingresarMedidas && (
            <div
              className={
                "form-group mb-1 " +
                (ingresarMedidas ? "col-6 col-md-3" : "col-6 col-md-2")
              }
            >
              <label htmlFor="selectSistemaMedida" className="mb-1 mt-2">
                {translate("global.units")}:
              </label>
              <select
                className="form-control form-control-sm font-small"
                onChange={(e) =>
                  setUnidad(e.target.value === "cm / kg" ? 0 : 1)
                }
                id="selectSistemaMedida"
              >
                <option defaultValue>inch / lb</option>
                <option>cm / kg</option>
              </select>
            </div>
          )}

          <div
            className={
              "form-group mb-1 " +
              (ingresarMedidas ? "col-6 col-md-2" : "col-6 col-md-2")
            }
          >
            <label htmlFor="inputPeso" className="mb-1 mt-2">
              {translate("global.weight")}{" "}
              <span className="font-xsmall">
                ({unidad === 0 ? "kg" : "lb"})
              </span>
              :{" "}
              <span className="font-xsmall">
                ({translate("global.optional")})
              </span>
            </label>
            <input
              className={
                "form-control form-control-sm font-small" +
                (errors.peso ? " is-invalid" : "")
              }
              type="number"
              id="inputPeso"
              placeholder="0"
              name="peso"
              ref={register}
            />
            {errors.peso && (
              <span className="text-error">{errors.peso.message}</span>
            )}
          </div>

          {!ingresarMedidas && (
            <div
              className={
                "form-group mb-1 col-3 " +
                (ingresarMedidas ? "col-md-3" : "col-6 col-md-2")
              }
            >
              <label htmlFor="selectTamano" className="mb-1 mt-2">
                {translate("global.size")}:
              </label>
              <select
                className="form-control form-control-sm font-small"
                id="selectTamano"
                name="size"
                ref={register}
              >
                <option defaultValue>P</option>
                <option>M</option>
                <option>G</option>
              </select>
            </div>
          )}
        </div>

        {ingresarMedidas && <FieldSizes register={register} unidad={unidad} />}

        <div className="form-row mx-0">
          <div className="form-group col-sm-12 col-md-6 mb-1">
            <label htmlFor="selectBoxit" className="mb-1 mt-2">
              <span className="text-error text-bold">*</span> Boxit:
            </label>
            <PlatformSearchList
              register={register}
              errors={errors}
              setPlatformSelect={setPlatformSelect}
              platfSelect={platformSelect}
              valueId={"plataforma"}
              readOnly={false}
              setValue={setValue}
              idPlatform={idPlatform.current}
              handleIsBoxit={handleIsBoxit}
              defaultPlatformId={props.idPlatformSelect}
            />
          </div>

          {((platformSelect && platformSelect?.tipoBoxit === 1) ||
            userContext.user?.IsAdmin) &&
            userRole >= 3 &&
            isBoxit &&
            idPlatform.current !== translate("global.select") &&
            idPlatform.current !== "" && (
              <div className="form-group col-sm-12 col-md-6 mb-1">
                <label htmlFor="selectPuertas" className="mb-1 mt-2">
                  {translate(
                    "dashboard.packages.loadpackages.formProvider.doorsAvailable"
                  )}
                  :
                </label>
                <DoorList
                  errors={errors}
                  register={register}
                  nameShow={translate("global.select")}
                  idShow={idPlatform.current}
                  readOnly={false}
                  isBoxit
                />
              </div>
            )}
        </div>

        {(isBoxit || userContext.user?.IsAdmin) && (
          <div className="form-row mx-0">
            <div className="form-group col-6 col-md-3 mb-1">
              <label htmlFor="inputMontoPagar" className="mb-1 mt-2">
                {translate(
                  "dashboard.packages.loadpackages.formProvider.amount"
                )}{" "}
                <span className="font-xsmall">(B/)</span>:
                <Tooltip
                  info={translate(
                    "dashboard.packages.loadpackages.formProvider.amount.info"
                  )}
                  className="ml-1"
                />
              </label>
              <input
                className="form-control form-control-sm font-small"
                type="number"
                step=".01"
                id="inputMontoPagar"
                placeholder="0"
                name="montoPagar"
                ref={register}
              />
              {errors.montoPagar && (
                <span className="text-error">{errors.montoPagar.message}</span>
              )}
            </div>
          </div>
        )}

        <div className="form-row mx-0 d-flex justify-content-center align-items-center">
          <div className="form-group col-sm-12 col-md-8 m-0 text-center">
            <button
              className="btn btn-outline-fucsia m-2"
              type="reset"
              onClick={() => resetFormB2BEmprendedores()}
            >
              {translate("global.clean")}
            </button>
            <button
              className="btn btn-fucsia m-2"
              type="submit"
              disabled={isSendingData}
            >
              {translate("global.create")}
            </button>
          </div>
        </div>

        <LockerSizes unidad={unidad} />
      </form>
    </>
  );
};

export default FormB2BEmprendedores;
