import React, { useEffect, useState, useContext } from "react";
import {
  getClientDomain,
  getToken,
  getUser,
  translate,
  verifyHideCountry,
  verifyHideCountryItems,
} from "../../../utils/Common";
// stylesheet
import "./index.css";
// components
import FormPersonalInformation from "./FormPersonalInformation";
import FormUpdatePasswords from "./FormUpdatePasswords";
import FormBusinesslInformation from "./FormBusinesslInformation";
import FormBankInformation from "./FormBankInformation";
import AuthContext from "../../../contexts/auth";
import ModalContext from "../../../contexts/modal";

// icons
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";

const UserProfile = (props) => {
  const [userInformation, setUserInformation] = useState();

  // para mostrar o no el formulario de update password
  const UserContext = useContext(AuthContext);
  const modalContext = useContext(ModalContext);

  const [getUserInfo, setGetUserInfo] = useState(false);
  const [validateUpdatePwd, setValidateUpdatePwd] = useState(false);
  const [validateUpdatePwdLocker, setValidateUpdatePwdLocker] = useState(false);
  const [isLockerPwdVisible, setIsLockerPwdVisible] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const getUserInformation = async () => {
      try {
        const response = await fetch(
          `${getClientDomain(2)}/users/s/${getUser(2).IdCliente}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${getToken()}`,
            },
            signal: abortController.signal,
          }
        );

        const result = await response.json();
        if (result?.code === 200)
          if (result?.payload) setUserInformation(result.payload);
      } catch (error) {
        console.log(error);
      }
    };

    getUserInformation();
    return () => abortController.abort();
  }, [getUserInfo]);

  return (
    <>
      { (
        <div className="dashboardCard">
          <div className="businessInfo-title text-left mb-2">
            <h5>{translate("dashboard.userprofile.business.title")}</h5>
            <div className="clearfix border-bottom"></div>
          </div>
          <div className="businessInfo-content">
            {/* Información de la empresa */}
            <FormBusinesslInformation
              userInformation={userInformation}
              validateUpdatePwd={validateUpdatePwd}
              validateUpdatePwdLocker={validateUpdatePwdLocker}
              setValidateUpdatePwdLocker={setValidateUpdatePwdLocker}
            />

            {/* Form to update password */}
            
            <div className="mt-4">
            {validateUpdatePwdLocker && (
              <FormUpdatePasswords
                getUserInfo={getUserInfo}
                setGetUserInfo={setGetUserInfo}
                userInformation={userInformation}
                validateUpdatePwd={validateUpdatePwd}
                setValidateUpdatePwd={setValidateUpdatePwd}
                validateUpdatePwdLocker={validateUpdatePwdLocker}
                setValidateUpdatePwdLocker={setValidateUpdatePwdLocker}
              />
            )}
            </div>
          </div>
        </div>
      )}
      <div className="dashboardCard">
        <div className="personalInfo-title text-left mb-2">
          <h5>{translate("dashboard.userprofile.personal.title")}</h5>
          <div className="clearfix border-bottom"></div>
        </div>
        <div className="personalInfo-content">
          {/* Información personal del usuario */}
          <FormPersonalInformation
            userInformation={userInformation}
            updateSidebarInfo={props.updateSidebarInfo}
            setValidateUpdatePwd={setValidateUpdatePwd}
            validateUpdatePwdLocker={validateUpdatePwdLocker}
            validateUpdatePwd={validateUpdatePwd}
            setValidateUpdatePwdLocker={setValidateUpdatePwdLocker}
          />
		   {/* Form to update password */}
		   {validateUpdatePwd  && (
            <FormUpdatePasswords
              userInformation={userInformation}
              validateUpdatePwdLocker={validateUpdatePwdLocker}
              setValidateUpdatePwdLocker={setValidateUpdatePwdLocker}
              validateUpdatePwd={validateUpdatePwd}
              setValidateUpdatePwd={setValidateUpdatePwd}
            />
          )}



{!UserContext.user.IsAdmin && verifyHideCountryItems(["us"]) && (
<>
<div className="personalInfo-title text-left mb-2 mt-4">
        <h5>{translate("dashboard.userprofile.business.credLocker")}</h5>
        <div className="clearfix border-bottom"></div>
      </div>
      <div className="row text-left">
        <div className="col-md-6 mb-1">
          <label htmlFor="inputUsuario">
            {translate("dashboard.userprofile.business.user")}:
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputUsuario">
                <FaIcons.FaUser size={16} />
              </span>
            </div>
            <input
              value={
                userInformation
                  ? Number(userInformation.IdUsuario) < 99
                    ? `0${userInformation.IdUsuario}`
                    : userInformation.IdUsuario
                  : ""
              }
              disabled
              type="text"
              className="form-control form-control-md"
              id="inputUsuario"
            />
          </div>
        </div>
        {!validateUpdatePwdLocker && !validateUpdatePwd && (
          <>
            <div className="col-md-6 mb-1">
              <label htmlFor="inputClave">
                {translate("dashboard.userprofile.business.password")}:
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text span-eye-pwd"
                    onClick={() => setIsLockerPwdVisible(!isLockerPwdVisible)}
                  >
                    {!isLockerPwdVisible ? (
                      <FiIcons.FiEye size={16} className="text-dark" />
                    ) : (
                      <FiIcons.FiEyeOff size={16} className="text-dark" />
                    )}
                  </span>
                </div>
                <input
                  value={
                    userInformation
                      ? Buffer.from(userInformation.Clave, "base64").toString(
                          "ascii"
                        )
                      : ""
                  }
                  disabled
                  type={isLockerPwdVisible ? "text" : "password"}
                  className="form-control form-control-md"
                  id="inputClave"
                />
              </div>
            </div>

            <div className="col-12 mb-1">
              <div className="row mb-0">
                <div className="col-12 mb-0 text-left">
                  <p className="mb-0">
                    {translate("dashboard.userprofile.business.instructions")}.
                  </p>

                  <button
                    className="btn btn-sm btn-hide ml-1"
                    type="button"
                    onClick={() =>
                      modalContext.confirm(
                        translate(
                          "dashboard.userprofile.business.modal.title"
                        ) + "!",
                        translate(
                          "dashboard.userprofile.business.modal.description"
                        ),
                        [
                          <button
                            type="button"
                            key="1"
                            className="btn btn-sm btn-update btn-outline-fucsia mr-2"
                            onClick={() => {
                              modalContext.setIsOpenModal(false);
                              setValidateUpdatePwdLocker(true);
                            }}
                          >
                            {translate(
                              "dashboard.userprofile.business.modal.accept"
                            )}
                          </button>,
                          <button
                            type="button"
                            key="2"
                            className="btn btn-sm btn-update btn-fucsia"
                            onClick={() => {
                              modalContext.setIsOpenModal(false);
                              setValidateUpdatePwdLocker(false);
                            }}
                          >
                            {translate(
                              "dashboard.userprofile.business.modal.cancel"
                            )}
                          </button>,
                        ],
                        true
                      )
                    }
                  >
                    <span className="font-bold">
                      {translate(
                        "dashboard.userprofile.business.buttonCredLocker"
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
</>
) }
   

          {/* Form to update password */}
          {validateUpdatePwdLocker && !UserContext.user.IsAdmin && (
            <FormUpdatePasswords
              getUserInfo={getUserInfo}
              setGetUserInfo={setGetUserInfo}
              userInformation={userInformation}
              validateUpdatePwd={validateUpdatePwd}
              setValidateUpdatePwd={setValidateUpdatePwd}
              validateUpdatePwdLocker={validateUpdatePwdLocker}
              setValidateUpdatePwdLocker={setValidateUpdatePwdLocker}
            />
          )}

         {verifyHideCountryItems(["us", "br"]) && (<FormBankInformation userInformation={userInformation} />)} 

         
        </div>
      </div>
    </>
  );
};

export default UserProfile;
